import { createApp } from 'vue'
import App from '@/App.vue'
// import '@/registerServiceWorker'
import '@/unregisterServiceWorker'
import router from '@/router'
import { WebSocketRpc } from '@/rpc_client/WebSocketRpc'
import { key } from '@/rpc_client/useStore'
import { RpcClient, onAuthChanged } from '@/rpc_client/apiAbstract'
import { createStore } from '@/generated/store'
import { createGlobalPropsAndProvideApi, protocolVersion } from '@/generated/api'
import { getRedirectLinkFromQueryMap } from './rpc_client/misc'

// 1) Rpc client init
let url = '';
if (process.env.NODE_ENV === 'production') url = 'wss://api.factory.tridecagram.ru/';
else url = 'ws://localhost:1313';
const wsp = new WebSocketRpc(url);
const client = new RpcClient(wsp, protocolVersion);

// 2) Store creation with custom and model data
// create custom store data and typings through module augmentation
interface CustomStoreData{
  appName: string;
}
declare module '@/generated/store' {
  interface State {
    custom: CustomStoreData
  }
}

const customData: CustomStoreData = {
  appName: 'marketplace'
};
// create custom store mutations
// const customMutations = {
//   authChanged (state: State, payload: { isAuthenticated: boolean, userPhone: string, userRole: string }) {
//     state.custom.isAuthenticated = payload.isAuthenticated; state.custom.userPhone = payload.userPhone; state.custom.userRole = payload.userRole;
//   }
// }

const customMutations = {};
const store = createStore(client, wsp, { custom: customData }, customMutations);

// 3) Main app creating
export const app = createApp(App).use(store, key).use(router);

// 4) Inserting api objects and rpc client as global properties and provide for composition API
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $RpcClient: RpcClient
  }
}
app.config.globalProperties.$RpcClient = client; app.provide('RpcClient', client);
createGlobalPropsAndProvideApi(app, wsp);

// 5) Main app mounting
const vm = app.mount('#app');

// 6) Setup redirecting to login page
router.beforeEach((to, from, next) => {
  if (client.token.length === 0) {
    if (to.name !== 'Login') { next({ name: 'Login' }); } else { next(); }
  } else {
    next();
  }
})

onAuthChanged.addListener(newAuth => {
  if (newAuth.isAuthenticated && router.currentRoute.value.name === 'Login') { 
    let queryMap = new Map(Object.entries(router.currentRoute.value.query));
    let redirectLink = getRedirectLinkFromQueryMap(queryMap);
    router.push(redirectLink) 
  } 
});

// 7) Connect client to server
client.connectToServer();

// 8) Custom directive
app.directive('scroll', {
  mounted (el, binding) { // When the bound element is inserted into the DOM...
    if (binding.value) { // banding is object that contains value pased to directive 'v-scroll='value''
      el.scrollIntoView({ behavior: 'smooth', block: 'center' }) // Scroll to element
    }
  }
})

// app.directive('click-outside', {
//   beforeMount (el, binding) {
//     el.clickOutsideEvent = function (event: Event) {
//       if (binding.value === null) return;

//       if (!(el === event.target || el.contains(event.target))) {
//         if (typeof binding.value === 'function') {
//           binding.value();
//         }
//       }
//     }
//     document.addEventListener('mouseup', el.clickOutsideEvent);
//   },
//   unmounted (el) {
//     document.removeEventListener('mouseup', el.clickOutsideEvent);
//   }
// })
