import { WebSocketRpc } from '@/rpc_client/WebSocketRpc';
import Channel from 'chnl';
import { UAParser } from 'ua-parser-js';
import { client_driven_msg, rpc_msg, server_driven_msg, object_update_msg } from '@/rpc_client/messages';
import { isEmailValid, extractPhoneRu, UserInfo } from './misc';
import Long from 'long';

export const muteError = () => {} // eslint-disable-line
export const propagateError = (error: Error) => { throw error }
export const returnFalse = () => false
export const returnTrue = () => true

export const extractEntryFieldValue = (field: object_update_msg.IEntryField) => {
  if (field.s !== null) return field.s;
  if (field.b !== null) return field.b;
  if (field.i !== null) return field.i;
  if (field.u !== null) return field.u;
  if (field.dt !== null) return field.dt;
  if (field.f !== null) return field.f;
  if (field.byt !== null) return field.byt;
  return false;
}

// Abstract rpc objects

export abstract class AbstractObject_Remote {
  abstract objectId: number
  protected socket!: WebSocketRpc

  constructor (socket: WebSocketRpc) {
    this.socket = socket;
  }
}

export abstract class AbstractModel_Remote extends AbstractObject_Remote {
  modelInitialized = false
  modelUpdateMark = 0

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void

  clearModel () {
    this.setValid(false);
    this.clearAll();
    this.modelInitialized = false;
  }

  resetModel (modelData: Uint8Array) {
    this.setValid(false);
    this.resetAll(modelData);
    this.modelInitialized = true;
    this.setValid(true);
  }
}

// Client

// for client specific proxy models reloading after connection loss we have to record all messages that can reload models in server
class ModelReloadFunctionsList {
  insert (entry: client_driven_msg.ClientRequest) {
    let found = false;
    for (let i = 0; i < this.list.length; i++) {
      if (this.list.at(i)?.object === entry.object && this.list.at(i)?.method === entry.method) {
        this.list[i].custom_encoded = entry.custom_encoded;
        found = true;
        break;
      }
    }
    if (!found) this.list.push(entry)
  }

  list: Array<client_driven_msg.ClientRequest> = []
}

export const modelReloadFunctionsList: ModelReloadFunctionsList = new ModelReloadFunctionsList();

export const onAuthChanged = new Channel();
export const onConnectionChanged = new Channel();
export const onRequestChanged = new Channel();

export function closeRequestError () {
  onRequestChanged.dispatch({ pending: false, error: false });
}

export class RpcClient {
  socket
  #protocolVersion: number
  token = ''
  // max-age is month for now
  // todo: ajust max-age
  tokenCookieArgs = 'path=/;max-age=2678400;' + (process.env.NODE_ENV === 'production' ? 'samesite=strict;secure;' : '')
  // #serverStartTime = 0
  // #serverStartTimeSet = false
  isAuthenticated = false
  userPhone = ''
  isPolicyAccepted = false
  agentContractRequested = false
  agentContractConcluded = false
  userRole = 'Undefined'
  isConnected = false
  #clientInitialised = false

  #rpcModels: Array<AbstractModel_Remote> = []
  // Map<modelId, true/false> map for safe asyn deletion
  #modelSubscriptionMap: Map<number, boolean> = new Map()

  constructor (socket: WebSocketRpc, protocolVersion: number) {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let pair = cookies[i].split('=').map(i => i.trim());
      if (pair[0] === 'token') this.token = pair[1];
    }

    this.socket = socket;
    this.#protocolVersion = protocolVersion;

    this.socket.onClose.addListener(this.connectToServer.bind(this));
    this.socket.onUnpackedMessage.addListener(this.processRequest.bind(this));
  }

  setModels (models: Array<AbstractModel_Remote>): void {
    this.#rpcModels = models;
  }

  connectToServer () {
    if (this.isConnected) {
      this.isConnected = false;
      onConnectionChanged.dispatch({ isConnected: false });
    }

    // todo: serverStartTime check disabled because in case of server fault client could connect to another server, start time is different there,
    // it will cause whole page hard refresh. But we are loosing ability to reload all models on clients in case of database hot patching.
    // (Db patching doesnt change protocol version -> clients will not refresh)
    // Db hot patching can be achieved by adding cli arguments to backend app on rollout restart, that will cause to page refresh or model reloading for connecting clients.
    this.socket.open()
      // .then(this.checkServerStartTime.bind(this))
      .then(async () => { await this.protocolVersion() })
      .then(async () => {
        if (this.token.length) {
          if (!await this.loginByToken()) return false; 
        }
        this.isConnected = true;
        onConnectionChanged.dispatch({ isConnected: true });
        this.#clientInitialised = true;
      })
      .catch(muteError); // onClose callback will call reconnectOnClose automatically, no need to catch error
  }

  // 0 return value cause js crash in protobufjs library
  // Return values:
  // -1 = ok, -2 = old protocolVersion, -3 = error
  // > 0 = auth ban time expiration 
  async auth (phone: string, communicationType: client_driven_msg.CommunicationType): Promise<Long> {
    onRequestChanged.dispatch({ pending: true, error: false });
    if (!phone.length || this.token.length) return Long.fromNumber(-3);
    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.AuthMethod,
      auth: { phone, communication_type: communicationType, protocol_version: Long.fromNumber(this.#protocolVersion) }
    });
    const response = client_driven_msg.AuthResponse.decode((
      await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error })
    ).data_encoded);
    onRequestChanged.dispatch({ pending: false, error: false });
    if (response.time_ms.eq(-2)) { document.location.reload(); return Long.fromNumber(-3); }
    this.userPhone = phone;
    return response.time_ms;
  }

  async loginByCode (pass: string): Promise<boolean> {
    onRequestChanged.dispatch({ pending: true, error: false });
    const uaParser = new UAParser(window.navigator.userAgent);
    const browser = uaParser.getBrowser().name ?? '';
    const os = (uaParser.getOS().name ?? '') + (uaParser.getOS().version ?? '');

    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.LoginMethod,
      login: { pass, browser, os, protocol_version: Long.fromNumber(this.#protocolVersion) }
    });

    const response = client_driven_msg.LoginResponse.decode((
      await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; })
    ).data_encoded);
    onRequestChanged.dispatch({ pending: false, error: false });
    if (response.result) {
      if (response.reload_app) { document.location.reload(); return false; }
      this.token = response.token!; document.cookie = 'token=' + response.token + ';' + this.tokenCookieArgs;
      this.isAuthenticated = true;
      this.userRole = response.role!;
      this.isPolicyAccepted = response.is_policy_accepted!;
      this.agentContractRequested = response.agent_contract_requested!;
      this.agentContractConcluded = response.agent_contract_concluded!;
      onAuthChanged.dispatch({
        isAuthenticated: true,
        userPhone: this.userPhone,
        userRole: response.role,
        isPolicyAccepted: response.is_policy_accepted,
        agentContractRequested: response.agent_contract_requested,
        agentContractConcluded: response.agent_contract_concluded
      });
    }
    return response.result;
  }

  async loginByToken (): Promise<boolean> {
    if (!this.token.length) return false;
    onRequestChanged.dispatch({ pending: true, error: false });
    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.LoginMethod,
      login: { token: this.token, protocol_version: Long.fromNumber(this.#protocolVersion) }
    });

    const response = client_driven_msg.LoginResponse.decode(
      (await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => {
        this.token = ''; document.cookie = 'token=;' + this.tokenCookieArgs; document.location.reload(); throw error;
      })).data_encoded
    );
    onRequestChanged.dispatch({ pending: false, error: false });
    if (response.reload_app) { document.location.reload(); return false; }

    this.token = response.token!; document.cookie = 'token=' + response.token + ';' + this.tokenCookieArgs;
    this.isAuthenticated = true;
    this.userRole = response.role!;
    this.userPhone = response.phone!;
    this.isPolicyAccepted = response.is_policy_accepted!;
    this.agentContractRequested = response.agent_contract_requested!;
    this.agentContractConcluded = response.agent_contract_concluded!;
    onAuthChanged.dispatch({
      isAuthenticated: true,
      userPhone: response.phone,
      userRole: response.role,
      isPolicyAccepted: response.is_policy_accepted,
      agentContractRequested: response.agent_contract_requested,
      agentContractConcluded: response.agent_contract_concluded
    });

    if (!this.#clientInitialised) return true;

    // refresh models in case of reconnect (note that in case of reconnection to another server model marks are differ and models will be reloaded anyway)

    this.#modelSubscriptionMap.forEach((val, modelId, map) => { if (!val) map.delete(modelId) });

    let modelsToUpdate = [...this.#modelSubscriptionMap.keys()];
    this.#modelSubscriptionMap.forEach((val, modelId) => {
      const model = this.#rpcModels.find(model => model.objectId === modelId);
      if (!model) return;
      if (response.model_marks.find(mark => mark.model_id === model.objectId)?.mark === model.modelUpdateMark) {
        const index = modelsToUpdate.lastIndexOf(model.objectId);
        modelsToUpdate.splice(index, 1);
      }
    })

    const modelsToSubscribe = [...this.#modelSubscriptionMap.keys()];
    this.#modelSubscriptionMap.clear();
    for (let modelId of modelsToSubscribe) {
      await this.subscribeToModel(modelId, modelsToUpdate.lastIndexOf(modelId) !== -1).catch(muteError); // error window have been activated in subscribeToModel(), just mute here
    }

    // client proxy models have to be cached in server after reconnect -> send recorded messages
    onRequestChanged.dispatch({ pending: true, error: false });
    for (let entry of modelReloadFunctionsList.list) {
      await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(entry).finish()).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    }
    onRequestChanged.dispatch({ pending: false, error: false });

    return true;
  }

  async logout (): Promise<boolean> {
    onRequestChanged.dispatch({ pending: true, error: false });
    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.LogoutMethod
    });

    await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish()).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    this.#modelSubscriptionMap.clear();
    this.userPhone = '';
    this.token = ''; document.cookie = 'token=;' + this.tokenCookieArgs;
    this.isAuthenticated = false;
    this.userRole = '';
    onAuthChanged.dispatch({ isAuthenticated: false, userPhone: '', userRole: '' });
    // Whole page hard refresh to purge components cache
    // todo: soft reset with cached components destroy
    document.location.reload();
    return true;
  }

  /* async checkServerStartTime (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.ServerStartTimeMethod
    });
    const response = client_driven_msg.ServerStartTimeResponse.decode((
      await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish()).catch(propagateError)
    ).data_encoded);

    if (this.#serverStartTimeSet) {
      if (response.time_ms.toNumber() !== this.#serverStartTime) {
        document.location.reload();
        throw new Error(); // do not remove this because it stops js script
      }
    } else {
      this.#serverStartTime = response.time_ms.toNumber();
      this.#serverStartTimeSet = true;
    }
    return true;
  } */

  async subscribeToModel (modelId: number, fetchModel = true): Promise<boolean> {
    if (this.#modelSubscriptionMap.get(modelId) === true) return true;
    this.#modelSubscriptionMap.set(modelId, true);

    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.SubscribeMethod,
      subscribe: { model_id: modelId, fetch_model: fetchModel }
    });

    onRequestChanged.dispatch({ pending: true, error: false });
    const response = client_driven_msg.SubscribeResponse.decode((
      await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; })
    ).data_encoded);

    if (fetchModel) {
      for (let model of this.#rpcModels) {
        if (model.objectId === modelId) {
          const resetModel = model.resetModel.bind(model);
          resetModel(response.data_encoded!);
          model.modelUpdateMark = response.mark!;
          break;
        }
      }
    }
    onRequestChanged.dispatch({ pending: false, error: false });

    return true;
  }

  async unsubscribeFromModel (modelId: number): Promise<boolean> {
    if (this.#modelSubscriptionMap.get(modelId) !== true) return true;
    this.#modelSubscriptionMap.set(modelId, false);

    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.UnsubscribeMethod,
      unsubscribe: { model_id: modelId }
    });

    onRequestChanged.dispatch({ pending: true, error: false });
    await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish()).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });

    this.#rpcModels.find(model => model.objectId === modelId)?.clearModel();

    return true;
  }

  async userInfo (payload: UserInfo): Promise<boolean> {
    if (payload.email !== undefined) {
      payload.email = payload.email.toLocaleLowerCase();
      if (!isEmailValid(payload.email)) return false
    }

    if (payload.phone !== undefined) {
      let phone = extractPhoneRu(payload.phone);
      if (!phone.length) { return false }
      payload.phone = phone
    }

    if (payload.is_policy_accepted !== undefined) { this.isPolicyAccepted = payload.is_policy_accepted }

    if (payload.agent_contract_requested !== undefined) { this.agentContractRequested = payload.agent_contract_requested }
    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.UserInfoMethod,
      user_info: payload
    });

    onRequestChanged.dispatch({ pending: true, error: false });
    await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish()).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });

    return true;
  }

  async protocolVersion (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({
      method: client_driven_msg.Method.ProtocolVersionMethod
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const response = client_driven_msg.ProtocolVersionResponse.decode((
      await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; })
    ).data_encoded);
    onRequestChanged.dispatch({ pending: false, error: false });
    if (!response.version.eq(this.#protocolVersion)) { document.location.reload(); throw new Error(); }
    return true;
  }

  private async processRequest (data: rpc_msg.RpcMessage) {
    if (data.request_id !== null) return;// skip client request answers -> we want to catch only server requests
    if (data.server_request_id !== null) { /* currently no server requests that require answering */return; }

    const request = server_driven_msg.ServerRequest.decode(data.data_encoded!);

    if (request.method === server_driven_msg.Method.ObjectUpdateMethod) {
      for (let model of this.#rpcModels) {
        if (model.objectId === request.object_update?.model_id) {
          if (!model.modelInitialized) return;
          for (let update of request.object_update.array!) {
            if (update.update !== null) {
              const updateRow = model.updateRow.bind(model);
              updateRow(update.update!);
              model.modelUpdateMark = request.object_update!.model_mark!;
              continue;
            }
            if (update.add_encoded !== null) {
              const addRow = model.addRow.bind(model);
              addRow(update.add_encoded!);
              model.modelUpdateMark = request.object_update!.model_mark!;
              continue;
            }
            if (update.remove !== null) {
              const removeRow = model.removeRow.bind(model);
              removeRow(update.remove!);
              model.modelUpdateMark = request.object_update!.model_mark!;
              continue;
            }
            if (update.reset !== null) {
              const resetModel = model.resetModel.bind(model);
              resetModel(update.reset!);
              model.modelUpdateMark = request.object_update!.model_mark!;

              continue;
            }
          }
          break;
        }
      }
    }
  }
}
