/* eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars */
import * as $protobuf from 'protobufjs/minimal';

const $Reader = $protobuf.Reader; const $Writer = $protobuf.Writer; const $util = $protobuf.util;

const $root = $protobuf.roots.default || ($protobuf.roots.default = {});

export const object_update_msg = $root.object_update_msg = (() => {
  const object_update_msg = {};

  object_update_msg.EntryField = (function () {
    function EntryField (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    EntryField.prototype.role = null;
    EntryField.prototype.s = null;
    EntryField.prototype.i = null;
    EntryField.prototype.f = null;
    EntryField.prototype.dt = null;
    EntryField.prototype.b = null;
    EntryField.prototype.u = null;
    EntryField.prototype.byt = null;

    let $oneOfFields;

    Object.defineProperty(EntryField.prototype, '_role', {
      get: $util.oneOfGetter($oneOfFields = ['role']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(EntryField.prototype, 'value', {
      get: $util.oneOfGetter($oneOfFields = ['s', 'i', 'f', 'dt', 'b', 'u', 'byt']),
      set: $util.oneOfSetter($oneOfFields)
    });

    EntryField.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.role != null && Object.hasOwnProperty.call(message, 'role')) { writer.uint32(8).int32(message.role); }
      if (message.s != null && Object.hasOwnProperty.call(message, 's')) { writer.uint32(18).string(message.s); }
      if (message.i != null && Object.hasOwnProperty.call(message, 'i')) { writer.uint32(24).int32(message.i); }
      if (message.f != null && Object.hasOwnProperty.call(message, 'f')) { writer.uint32(37).float(message.f); }
      if (message.dt != null && Object.hasOwnProperty.call(message, 'dt')) { writer.uint32(40).int64(message.dt); }
      if (message.b != null && Object.hasOwnProperty.call(message, 'b')) { writer.uint32(48).bool(message.b); }
      if (message.u != null && Object.hasOwnProperty.call(message, 'u')) { writer.uint32(56).uint64(message.u); }
      if (message.byt != null && Object.hasOwnProperty.call(message, 'byt')) { writer.uint32(66).bytes(message.byt); }
      return writer;
    };

    EntryField.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.object_update_msg.EntryField();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.role = reader.int32();
            break;
          }
          case 2: {
            message.s = reader.string();
            break;
          }
          case 3: {
            message.i = reader.int32();
            break;
          }
          case 4: {
            message.f = reader.float();
            break;
          }
          case 5: {
            message.dt = reader.int64();
            break;
          }
          case 6: {
            message.b = reader.bool();
            break;
          }
          case 7: {
            message.u = reader.uint64();
            break;
          }
          case 8: {
            message.byt = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    EntryField.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/object_update_msg.EntryField';
    };

    return EntryField;
  })();

  object_update_msg.Update = (function () {
    function Update (properties) {
      this.data = [];
      this.index = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    Update.prototype.data = $util.emptyArray;
    Update.prototype.index = $util.emptyArray;

    Update.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.data != null && message.data.length) {
        for (let i = 0; i < message.data.length; ++i) { $root.object_update_msg.EntryField.encode(message.data[i], writer.uint32(10).fork()).ldelim(); } 
      }
      if (message.index != null && message.index.length) {
        for (let i = 0; i < message.index.length; ++i) { $root.object_update_msg.EntryField.encode(message.index[i], writer.uint32(18).fork()).ldelim(); } 
      }
      return writer;
    };

    Update.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.object_update_msg.Update();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.data && message.data.length)) { message.data = []; }
            message.data.push($root.object_update_msg.EntryField.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            if (!(message.index && message.index.length)) { message.index = []; }
            message.index.push($root.object_update_msg.EntryField.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    Update.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/object_update_msg.Update';
    };

    return Update;
  })();

  object_update_msg.Remove = (function () {
    function Remove (properties) {
      this.index = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    Remove.prototype.index = $util.emptyArray;

    Remove.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.index != null && message.index.length) {
        for (let i = 0; i < message.index.length; ++i) { $root.object_update_msg.EntryField.encode(message.index[i], writer.uint32(10).fork()).ldelim(); } 
      }
      return writer;
    };

    Remove.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.object_update_msg.Remove();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.index && message.index.length)) { message.index = []; }
            message.index.push($root.object_update_msg.EntryField.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    Remove.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/object_update_msg.Remove';
    };

    return Remove;
  })();

  object_update_msg.ObjectUpdate = (function () {
    function ObjectUpdate (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    ObjectUpdate.prototype.add_encoded = null;
    ObjectUpdate.prototype.update = null;
    ObjectUpdate.prototype.remove = null;
    ObjectUpdate.prototype.reset = null;

    let $oneOfFields;

    Object.defineProperty(ObjectUpdate.prototype, 'payload', {
      get: $util.oneOfGetter($oneOfFields = ['add_encoded', 'update', 'remove', 'reset']),
      set: $util.oneOfSetter($oneOfFields)
    });

    ObjectUpdate.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.add_encoded != null && Object.hasOwnProperty.call(message, 'add_encoded')) { writer.uint32(10).bytes(message.add_encoded); }
      if (message.update != null && Object.hasOwnProperty.call(message, 'update')) { $root.object_update_msg.Update.encode(message.update, writer.uint32(18).fork()).ldelim(); }
      if (message.remove != null && Object.hasOwnProperty.call(message, 'remove')) { $root.object_update_msg.Remove.encode(message.remove, writer.uint32(26).fork()).ldelim(); }
      if (message.reset != null && Object.hasOwnProperty.call(message, 'reset')) { writer.uint32(34).bytes(message.reset); }
      return writer;
    };

    ObjectUpdate.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.object_update_msg.ObjectUpdate();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.add_encoded = reader.bytes();
            break;
          }
          case 2: {
            message.update = $root.object_update_msg.Update.decode(reader, reader.uint32());
            break;
          }
          case 3: {
            message.remove = $root.object_update_msg.Remove.decode(reader, reader.uint32());
            break;
          }
          case 4: {
            message.reset = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    ObjectUpdate.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/object_update_msg.ObjectUpdate';
    };

    return ObjectUpdate;
  })();

  object_update_msg.ObjectUpdateArray = (function () {
    function ObjectUpdateArray (properties) {
      this.array = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    ObjectUpdateArray.prototype.array = $util.emptyArray;
    ObjectUpdateArray.prototype.model_id = null;
    ObjectUpdateArray.prototype.model_mark = null;

    let $oneOfFields;

    Object.defineProperty(ObjectUpdateArray.prototype, '_model_id', {
      get: $util.oneOfGetter($oneOfFields = ['model_id']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(ObjectUpdateArray.prototype, '_model_mark', {
      get: $util.oneOfGetter($oneOfFields = ['model_mark']),
      set: $util.oneOfSetter($oneOfFields)
    });

    ObjectUpdateArray.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.array != null && message.array.length) {
        for (let i = 0; i < message.array.length; ++i) { $root.object_update_msg.ObjectUpdate.encode(message.array[i], writer.uint32(10).fork()).ldelim(); } 
      }
      if (message.model_id != null && Object.hasOwnProperty.call(message, 'model_id')) { writer.uint32(16).int32(message.model_id); }
      if (message.model_mark != null && Object.hasOwnProperty.call(message, 'model_mark')) { writer.uint32(24).int32(message.model_mark); }
      return writer;
    };

    ObjectUpdateArray.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.object_update_msg.ObjectUpdateArray();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.array && message.array.length)) { message.array = []; }
            message.array.push($root.object_update_msg.ObjectUpdate.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.model_id = reader.int32();
            break;
          }
          case 3: {
            message.model_mark = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    ObjectUpdateArray.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/object_update_msg.ObjectUpdateArray';
    };

    return ObjectUpdateArray;
  })();

  return object_update_msg;
})();

export const rpc_msg = $root.rpc_msg = (() => {
  const rpc_msg = {};

  rpc_msg.RpcMessage = (function () {
    function RpcMessage (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    RpcMessage.prototype.data_encoded = null;
    RpcMessage.prototype.request_id = null;
    RpcMessage.prototype.server_request_id = null;

    let $oneOfFields;

    Object.defineProperty(RpcMessage.prototype, '_data_encoded', {
      get: $util.oneOfGetter($oneOfFields = ['data_encoded']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(RpcMessage.prototype, '_request_id', {
      get: $util.oneOfGetter($oneOfFields = ['request_id']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(RpcMessage.prototype, '_server_request_id', {
      get: $util.oneOfGetter($oneOfFields = ['server_request_id']),
      set: $util.oneOfSetter($oneOfFields)
    });

    RpcMessage.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.data_encoded != null && Object.hasOwnProperty.call(message, 'data_encoded')) { writer.uint32(10).bytes(message.data_encoded); }
      if (message.request_id != null && Object.hasOwnProperty.call(message, 'request_id')) { writer.uint32(16).uint32(message.request_id); }
      if (message.server_request_id != null && Object.hasOwnProperty.call(message, 'server_request_id')) { writer.uint32(24).uint32(message.server_request_id); }
      return writer;
    };

    RpcMessage.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.rpc_msg.RpcMessage();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data_encoded = reader.bytes();
            break;
          }
          case 2: {
            message.request_id = reader.uint32();
            break;
          }
          case 3: {
            message.server_request_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    RpcMessage.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/rpc_msg.RpcMessage';
    };

    return RpcMessage;
  })();

  return rpc_msg;
})();

export const server_driven_msg = $root.server_driven_msg = (() => {
  const server_driven_msg = {};

  server_driven_msg.ServerRequest = (function () {
    function ServerRequest (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    ServerRequest.prototype.method = 0;
    ServerRequest.prototype.object = null;
    ServerRequest.prototype.custom_encoded = null;
    ServerRequest.prototype.object_update = null;

    let $oneOfFields;

    Object.defineProperty(ServerRequest.prototype, '_object', {
      get: $util.oneOfGetter($oneOfFields = ['object']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(ServerRequest.prototype, 'args', {
      get: $util.oneOfGetter($oneOfFields = ['custom_encoded', 'object_update']),
      set: $util.oneOfSetter($oneOfFields)
    });

    ServerRequest.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.method != null && Object.hasOwnProperty.call(message, 'method')) { writer.uint32(8).int32(message.method); }
      if (message.object != null && Object.hasOwnProperty.call(message, 'object')) { writer.uint32(16).int32(message.object); }
      if (message.custom_encoded != null && Object.hasOwnProperty.call(message, 'custom_encoded')) { writer.uint32(26).bytes(message.custom_encoded); }
      if (message.object_update != null && Object.hasOwnProperty.call(message, 'object_update')) { $root.object_update_msg.ObjectUpdateArray.encode(message.object_update, writer.uint32(34).fork()).ldelim(); }
      return writer;
    };

    ServerRequest.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.server_driven_msg.ServerRequest();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.method = reader.int32();
            break;
          }
          case 2: {
            message.object = reader.int32();
            break;
          }
          case 3: {
            message.custom_encoded = reader.bytes();
            break;
          }
          case 4: {
            message.object_update = $root.object_update_msg.ObjectUpdateArray.decode(reader, reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    ServerRequest.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/server_driven_msg.ServerRequest';
    };

    return ServerRequest;
  })();

  server_driven_msg.Method = (function () {
    const valuesById = {}; const values = Object.create(valuesById);
    values[valuesById[0] = 'UnknownMethod'] = 0;
    values[valuesById[1] = 'ObjectUpdateMethod'] = 1;
    return values;
  })();

  return server_driven_msg;
})();

export const client_driven_msg = $root.client_driven_msg = (() => {
  const client_driven_msg = {};

  client_driven_msg.ClientRequest = (function () {
    function ClientRequest (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    ClientRequest.prototype.method = 0;
    ClientRequest.prototype.object = null;
    ClientRequest.prototype.custom_encoded = null;
    ClientRequest.prototype.auth = null;
    ClientRequest.prototype.login = null;
    ClientRequest.prototype.subscribe = null;
    ClientRequest.prototype.unsubscribe = null;
    ClientRequest.prototype.user_info = null;

    let $oneOfFields;

    Object.defineProperty(ClientRequest.prototype, '_object', {
      get: $util.oneOfGetter($oneOfFields = ['object']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(ClientRequest.prototype, 'args', {
      get: $util.oneOfGetter($oneOfFields = ['custom_encoded', 'auth', 'login', 'subscribe', 'unsubscribe', 'user_info']),
      set: $util.oneOfSetter($oneOfFields)
    });

    ClientRequest.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.method != null && Object.hasOwnProperty.call(message, 'method')) { writer.uint32(8).int32(message.method); }
      if (message.object != null && Object.hasOwnProperty.call(message, 'object')) { writer.uint32(16).int32(message.object); }
      if (message.custom_encoded != null && Object.hasOwnProperty.call(message, 'custom_encoded')) { writer.uint32(26).bytes(message.custom_encoded); }
      if (message.auth != null && Object.hasOwnProperty.call(message, 'auth')) { $root.client_driven_msg.Auth.encode(message.auth, writer.uint32(34).fork()).ldelim(); }
      if (message.login != null && Object.hasOwnProperty.call(message, 'login')) { $root.client_driven_msg.Login.encode(message.login, writer.uint32(42).fork()).ldelim(); }
      if (message.subscribe != null && Object.hasOwnProperty.call(message, 'subscribe')) { $root.client_driven_msg.Subscribe.encode(message.subscribe, writer.uint32(50).fork()).ldelim(); }
      if (message.unsubscribe != null && Object.hasOwnProperty.call(message, 'unsubscribe')) { $root.client_driven_msg.Unsubscribe.encode(message.unsubscribe, writer.uint32(58).fork()).ldelim(); }
      if (message.user_info != null && Object.hasOwnProperty.call(message, 'user_info')) { $root.client_driven_msg.UserInfo.encode(message.user_info, writer.uint32(66).fork()).ldelim(); }
      return writer;
    };

    ClientRequest.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.ClientRequest();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.method = reader.int32();
            break;
          }
          case 2: {
            message.object = reader.int32();
            break;
          }
          case 3: {
            message.custom_encoded = reader.bytes();
            break;
          }
          case 4: {
            message.auth = $root.client_driven_msg.Auth.decode(reader, reader.uint32());
            break;
          }
          case 5: {
            message.login = $root.client_driven_msg.Login.decode(reader, reader.uint32());
            break;
          }
          case 6: {
            message.subscribe = $root.client_driven_msg.Subscribe.decode(reader, reader.uint32());
            break;
          }
          case 7: {
            message.unsubscribe = $root.client_driven_msg.Unsubscribe.decode(reader, reader.uint32());
            break;
          }
          case 8: {
            message.user_info = $root.client_driven_msg.UserInfo.decode(reader, reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    ClientRequest.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.ClientRequest';
    };

    return ClientRequest;
  })();

  client_driven_msg.Method = (function () {
    const valuesById = {}; const values = Object.create(valuesById);
    values[valuesById[0] = 'UnknownMethod'] = 0;
    values[valuesById[1] = 'AuthMethod'] = 1;
    values[valuesById[2] = 'LoginMethod'] = 2;
    values[valuesById[3] = 'LogoutMethod'] = 3;
    values[valuesById[4] = 'SubscribeMethod'] = 4;
    values[valuesById[5] = 'UnsubscribeMethod'] = 5;
    values[valuesById[6] = 'ServerStartTimeMethod'] = 6;
    values[valuesById[7] = 'UserInfoMethod'] = 7;
    values[valuesById[8] = 'ProtocolVersionMethod'] = 8;
    return values;
  })();

  client_driven_msg.CommunicationType = (function () {
    const valuesById = {}; const values = Object.create(valuesById);
    values[valuesById[0] = 'UnknownCommunicationType'] = 0;
    values[valuesById[1] = 'SMS'] = 1;
    values[valuesById[2] = 'WhatsApp'] = 2;
    values[valuesById[3] = 'Telegram'] = 3;
    values[valuesById[4] = 'Email'] = 4;
    return values;
  })();

  client_driven_msg.Auth = (function () {
    function Auth (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    Auth.prototype.phone = '';
    Auth.prototype.protocol_version = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    Auth.prototype.communication_type = 0;

    Auth.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.phone != null && Object.hasOwnProperty.call(message, 'phone')) { writer.uint32(10).string(message.phone); }
      if (message.protocol_version != null && Object.hasOwnProperty.call(message, 'protocol_version')) { writer.uint32(16).uint64(message.protocol_version); }
      if (message.communication_type != null && Object.hasOwnProperty.call(message, 'communication_type')) { writer.uint32(24).int32(message.communication_type); }
      return writer;
    };

    Auth.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.Auth();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.phone = reader.string();
            break;
          }
          case 2: {
            message.protocol_version = reader.uint64();
            break;
          }
          case 3: {
            message.communication_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    Auth.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.Auth';
    };

    return Auth;
  })();

  client_driven_msg.Login = (function () {
    function Login (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    Login.prototype.protocol_version = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    Login.prototype.token = null;
    Login.prototype.pass = null;
    Login.prototype.browser = null;
    Login.prototype.os = null;

    let $oneOfFields;

    Object.defineProperty(Login.prototype, '_token', {
      get: $util.oneOfGetter($oneOfFields = ['token']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(Login.prototype, '_pass', {
      get: $util.oneOfGetter($oneOfFields = ['pass']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(Login.prototype, '_browser', {
      get: $util.oneOfGetter($oneOfFields = ['browser']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(Login.prototype, '_os', {
      get: $util.oneOfGetter($oneOfFields = ['os']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Login.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.protocol_version != null && Object.hasOwnProperty.call(message, 'protocol_version')) { writer.uint32(8).uint64(message.protocol_version); }
      if (message.token != null && Object.hasOwnProperty.call(message, 'token')) { writer.uint32(18).string(message.token); }
      if (message.pass != null && Object.hasOwnProperty.call(message, 'pass')) { writer.uint32(26).string(message.pass); }
      if (message.browser != null && Object.hasOwnProperty.call(message, 'browser')) { writer.uint32(34).string(message.browser); }
      if (message.os != null && Object.hasOwnProperty.call(message, 'os')) { writer.uint32(42).string(message.os); }
      return writer;
    };

    Login.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.Login();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.protocol_version = reader.uint64();
            break;
          }
          case 2: {
            message.token = reader.string();
            break;
          }
          case 3: {
            message.pass = reader.string();
            break;
          }
          case 4: {
            message.browser = reader.string();
            break;
          }
          case 5: {
            message.os = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    Login.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.Login';
    };

    return Login;
  })();

  client_driven_msg.Subscribe = (function () {
    function Subscribe (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    Subscribe.prototype.model_id = 0;
    Subscribe.prototype.fetch_model = false;

    Subscribe.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.model_id != null && Object.hasOwnProperty.call(message, 'model_id')) { writer.uint32(8).int32(message.model_id); }
      if (message.fetch_model != null && Object.hasOwnProperty.call(message, 'fetch_model')) { writer.uint32(16).bool(message.fetch_model); }
      return writer;
    };

    Subscribe.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.Subscribe();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.model_id = reader.int32();
            break;
          }
          case 2: {
            message.fetch_model = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    Subscribe.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.Subscribe';
    };

    return Subscribe;
  })();

  client_driven_msg.Unsubscribe = (function () {
    function Unsubscribe (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    Unsubscribe.prototype.model_id = 0;

    Unsubscribe.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.model_id != null && Object.hasOwnProperty.call(message, 'model_id')) { writer.uint32(8).int32(message.model_id); }
      return writer;
    };

    Unsubscribe.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.Unsubscribe();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.model_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    Unsubscribe.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.Unsubscribe';
    };

    return Unsubscribe;
  })();

  client_driven_msg.UserInfo = (function () {
    function UserInfo (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    UserInfo.prototype.phone = null;
    UserInfo.prototype.email = null;
    UserInfo.prototype.name = null;
    UserInfo.prototype.surname = null;
    UserInfo.prototype.patronymic = null;
    UserInfo.prototype.communication_type = null;
    UserInfo.prototype.is_policy_accepted = null;
    UserInfo.prototype.agent_contract_requested = null;
    UserInfo.prototype.agent_contract_sent = null;
    UserInfo.prototype.agent_contract_concluded = null;

    let $oneOfFields;

    Object.defineProperty(UserInfo.prototype, '_phone', {
      get: $util.oneOfGetter($oneOfFields = ['phone']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_email', {
      get: $util.oneOfGetter($oneOfFields = ['email']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_name', {
      get: $util.oneOfGetter($oneOfFields = ['name']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_surname', {
      get: $util.oneOfGetter($oneOfFields = ['surname']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_patronymic', {
      get: $util.oneOfGetter($oneOfFields = ['patronymic']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_communication_type', {
      get: $util.oneOfGetter($oneOfFields = ['communication_type']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_is_policy_accepted', {
      get: $util.oneOfGetter($oneOfFields = ['is_policy_accepted']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_agent_contract_requested', {
      get: $util.oneOfGetter($oneOfFields = ['agent_contract_requested']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_agent_contract_sent', {
      get: $util.oneOfGetter($oneOfFields = ['agent_contract_sent']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(UserInfo.prototype, '_agent_contract_concluded', {
      get: $util.oneOfGetter($oneOfFields = ['agent_contract_concluded']),
      set: $util.oneOfSetter($oneOfFields)
    });

    UserInfo.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.phone != null && Object.hasOwnProperty.call(message, 'phone')) { writer.uint32(10).string(message.phone); }
      if (message.email != null && Object.hasOwnProperty.call(message, 'email')) { writer.uint32(18).string(message.email); }
      if (message.name != null && Object.hasOwnProperty.call(message, 'name')) { writer.uint32(26).string(message.name); }
      if (message.surname != null && Object.hasOwnProperty.call(message, 'surname')) { writer.uint32(34).string(message.surname); }
      if (message.patronymic != null && Object.hasOwnProperty.call(message, 'patronymic')) { writer.uint32(42).string(message.patronymic); }
      if (message.communication_type != null && Object.hasOwnProperty.call(message, 'communication_type')) { writer.uint32(48).int32(message.communication_type); }
      if (message.is_policy_accepted != null && Object.hasOwnProperty.call(message, 'is_policy_accepted')) { writer.uint32(56).bool(message.is_policy_accepted); }
      if (message.agent_contract_requested != null && Object.hasOwnProperty.call(message, 'agent_contract_requested')) { writer.uint32(64).bool(message.agent_contract_requested); }
      if (message.agent_contract_sent != null && Object.hasOwnProperty.call(message, 'agent_contract_sent')) { writer.uint32(72).bool(message.agent_contract_sent); }
      if (message.agent_contract_concluded != null && Object.hasOwnProperty.call(message, 'agent_contract_concluded')) { writer.uint32(80).bool(message.agent_contract_concluded); }
      return writer;
    };

    UserInfo.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.UserInfo();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.phone = reader.string();
            break;
          }
          case 2: {
            message.email = reader.string();
            break;
          }
          case 3: {
            message.name = reader.string();
            break;
          }
          case 4: {
            message.surname = reader.string();
            break;
          }
          case 5: {
            message.patronymic = reader.string();
            break;
          }
          case 6: {
            message.communication_type = reader.int32();
            break;
          }
          case 7: {
            message.is_policy_accepted = reader.bool();
            break;
          }
          case 8: {
            message.agent_contract_requested = reader.bool();
            break;
          }
          case 9: {
            message.agent_contract_sent = reader.bool();
            break;
          }
          case 10: {
            message.agent_contract_concluded = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    UserInfo.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.UserInfo';
    };

    return UserInfo;
  })();

  client_driven_msg.LoginResponse = (function () {
    function LoginResponse (properties) {
      this.model_marks = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    LoginResponse.prototype.result = false;
    LoginResponse.prototype.role = null;
    LoginResponse.prototype.token = null;
    LoginResponse.prototype.phone = null;
    LoginResponse.prototype.is_policy_accepted = null;
    LoginResponse.prototype.agent_contract_requested = null;
    LoginResponse.prototype.agent_contract_concluded = null;
    LoginResponse.prototype.model_marks = $util.emptyArray;
    LoginResponse.prototype.reload_app = null;

    let $oneOfFields;

    Object.defineProperty(LoginResponse.prototype, '_role', {
      get: $util.oneOfGetter($oneOfFields = ['role']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(LoginResponse.prototype, '_token', {
      get: $util.oneOfGetter($oneOfFields = ['token']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(LoginResponse.prototype, '_phone', {
      get: $util.oneOfGetter($oneOfFields = ['phone']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(LoginResponse.prototype, '_is_policy_accepted', {
      get: $util.oneOfGetter($oneOfFields = ['is_policy_accepted']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(LoginResponse.prototype, '_agent_contract_requested', {
      get: $util.oneOfGetter($oneOfFields = ['agent_contract_requested']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(LoginResponse.prototype, '_agent_contract_concluded', {
      get: $util.oneOfGetter($oneOfFields = ['agent_contract_concluded']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(LoginResponse.prototype, '_reload_app', {
      get: $util.oneOfGetter($oneOfFields = ['reload_app']),
      set: $util.oneOfSetter($oneOfFields)
    });

    LoginResponse.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.result != null && Object.hasOwnProperty.call(message, 'result')) { writer.uint32(8).bool(message.result); }
      if (message.role != null && Object.hasOwnProperty.call(message, 'role')) { writer.uint32(18).string(message.role); }
      if (message.token != null && Object.hasOwnProperty.call(message, 'token')) { writer.uint32(26).string(message.token); }
      if (message.phone != null && Object.hasOwnProperty.call(message, 'phone')) { writer.uint32(34).string(message.phone); }
      if (message.is_policy_accepted != null && Object.hasOwnProperty.call(message, 'is_policy_accepted')) { writer.uint32(40).bool(message.is_policy_accepted); }
      if (message.agent_contract_requested != null && Object.hasOwnProperty.call(message, 'agent_contract_requested')) { writer.uint32(48).bool(message.agent_contract_requested); }
      if (message.agent_contract_concluded != null && Object.hasOwnProperty.call(message, 'agent_contract_concluded')) { writer.uint32(56).bool(message.agent_contract_concluded); }
      if (message.model_marks != null && message.model_marks.length) {
        for (let i = 0; i < message.model_marks.length; ++i) { $root.client_driven_msg.LoginResponse.ModelMark.encode(message.model_marks[i], writer.uint32(66).fork()).ldelim(); } 
      }
      if (message.reload_app != null && Object.hasOwnProperty.call(message, 'reload_app')) { writer.uint32(72).bool(message.reload_app); }
      return writer;
    };

    LoginResponse.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.LoginResponse();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.bool();
            break;
          }
          case 2: {
            message.role = reader.string();
            break;
          }
          case 3: {
            message.token = reader.string();
            break;
          }
          case 4: {
            message.phone = reader.string();
            break;
          }
          case 5: {
            message.is_policy_accepted = reader.bool();
            break;
          }
          case 6: {
            message.agent_contract_requested = reader.bool();
            break;
          }
          case 7: {
            message.agent_contract_concluded = reader.bool();
            break;
          }
          case 8: {
            if (!(message.model_marks && message.model_marks.length)) { message.model_marks = []; }
            message.model_marks.push($root.client_driven_msg.LoginResponse.ModelMark.decode(reader, reader.uint32()));
            break;
          }
          case 9: {
            message.reload_app = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    LoginResponse.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.LoginResponse';
    };

    LoginResponse.ModelMark = (function () {
      function ModelMark (properties) {
        if (properties) {
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
            if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
          } 
        }
      }

      ModelMark.prototype.model_id = 0;
      ModelMark.prototype.mark = 0;

      ModelMark.encode = function encode (message, writer) {
        if (!writer) { writer = $Writer.create(); }
        if (message.model_id != null && Object.hasOwnProperty.call(message, 'model_id')) { writer.uint32(8).int32(message.model_id); }
        if (message.mark != null && Object.hasOwnProperty.call(message, 'mark')) { writer.uint32(16).int32(message.mark); }
        return writer;
      };

      ModelMark.decode = function decode (reader, length) {
        if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
        let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.LoginResponse.ModelMark();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.model_id = reader.int32();
              break;
            }
            case 2: {
              message.mark = reader.int32();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      ModelMark.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com';
        }
        return typeUrlPrefix + '/client_driven_msg.LoginResponse.ModelMark';
      };

      return ModelMark;
    })();

    return LoginResponse;
  })();

  client_driven_msg.AuthResponse = (function () {
    function AuthResponse (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    AuthResponse.prototype.time_ms = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    AuthResponse.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.time_ms != null && Object.hasOwnProperty.call(message, 'time_ms')) { writer.uint32(8).int64(message.time_ms); }
      return writer;
    };

    AuthResponse.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.AuthResponse();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.time_ms = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    AuthResponse.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.AuthResponse';
    };

    return AuthResponse;
  })();

  client_driven_msg.SubscribeResponse = (function () {
    function SubscribeResponse (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    SubscribeResponse.prototype.mark = null;
    SubscribeResponse.prototype.data_encoded = null;

    let $oneOfFields;

    Object.defineProperty(SubscribeResponse.prototype, '_mark', {
      get: $util.oneOfGetter($oneOfFields = ['mark']),
      set: $util.oneOfSetter($oneOfFields)
    });

    Object.defineProperty(SubscribeResponse.prototype, '_data_encoded', {
      get: $util.oneOfGetter($oneOfFields = ['data_encoded']),
      set: $util.oneOfSetter($oneOfFields)
    });

    SubscribeResponse.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.mark != null && Object.hasOwnProperty.call(message, 'mark')) { writer.uint32(8).int32(message.mark); }
      if (message.data_encoded != null && Object.hasOwnProperty.call(message, 'data_encoded')) { writer.uint32(18).bytes(message.data_encoded); }
      return writer;
    };

    SubscribeResponse.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.SubscribeResponse();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.mark = reader.int32();
            break;
          }
          case 2: {
            message.data_encoded = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    SubscribeResponse.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.SubscribeResponse';
    };

    return SubscribeResponse;
  })();

  client_driven_msg.ServerStartTimeResponse = (function () {
    function ServerStartTimeResponse (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    ServerStartTimeResponse.prototype.time_ms = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    ServerStartTimeResponse.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.time_ms != null && Object.hasOwnProperty.call(message, 'time_ms')) { writer.uint32(8).uint64(message.time_ms); }
      return writer;
    };

    ServerStartTimeResponse.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.ServerStartTimeResponse();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.time_ms = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    ServerStartTimeResponse.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.ServerStartTimeResponse';
    };

    return ServerStartTimeResponse;
  })();

  client_driven_msg.ProtocolVersionResponse = (function () {
    function ProtocolVersionResponse (properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
          if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
        } 
      }
    }

    ProtocolVersionResponse.prototype.version = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    ProtocolVersionResponse.encode = function encode (message, writer) {
      if (!writer) { writer = $Writer.create(); }
      if (message.version != null && Object.hasOwnProperty.call(message, 'version')) { writer.uint32(8).uint64(message.version); }
      return writer;
    };

    ProtocolVersionResponse.decode = function decode (reader, length) {
      if (!(reader instanceof $Reader)) { reader = $Reader.create(reader); }
      let end = length === undefined ? reader.len : reader.pos + length; let message = new $root.client_driven_msg.ProtocolVersionResponse();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.version = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    ProtocolVersionResponse.getTypeUrl = function getTypeUrl (typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/client_driven_msg.ProtocolVersionResponse';
    };

    return ProtocolVersionResponse;
  })();

  return client_driven_msg;
})();

export { $root as default };
