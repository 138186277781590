import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/rpc_client/service_components/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/outgoing-orders',
    name: 'OutgoingOrders',
    component: () => import('../views/OutgoingOrders.vue')
  },
  {
    path: '/incoming-orders',
    name: 'IncomingOrders',
    component: () => import('../views/IncomingOrders.vue')
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('../views/Organizations.vue')
  },
  {
    path: '/places',
    name: 'Places',
    component: () => import('../views/Places.vue')
  },
  {
    path: '/options',
    name: 'Options',
    component: () => import('../rpc_client/service_components/Options.vue')
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('@/rpc_client/service_components/Logs.vue')
  } 
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
