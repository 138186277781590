import WebSocketAsPromised from 'websocket-as-promised'
import { rpc_msg } from '@/rpc_client/messages'

export class WebSocketRpc extends WebSocketAsPromised {
  requestIdArray: Array<number> = [];
  
  constructor (url: string) {
    super(url, {
      createWebSocket: url => {
        const ws = new WebSocket(url);
        ws.binaryType = 'arraybuffer';
        return ws;
      },
      packMessage: data => {
        return rpc_msg.RpcMessage.encode(new rpc_msg.RpcMessage(data)).finish();
      },
      unpackMessage: data => {
        // @ts-expect-error: Its an ArrayBuffer
        return rpc_msg.RpcMessage.decode(new Uint8Array(data));
      },
      attachRequestId: (data, requestId) => {
        return { request_id: requestId as number, data_encoded: data };
      },
      extractRequestId: data => {
        let d = data as rpc_msg.RpcMessage;
        if (d.request_id) {
          let index = this.requestIdArray.indexOf(d.request_id as number);
          if (index > -1) this.requestIdArray.splice(index, 1);
          return d.request_id as number;
        } else return undefined;
      }
    })

    this.sendRequest = (data, options = { timeout: 10000 }): Promise<any> => {
      let idGenerated = false;
      let generatedId = 0;
      while (!idGenerated) {
        generatedId = Math.floor(Math.random() * 1000);
        if (!this.requestIdArray.includes(generatedId)) {
          this.requestIdArray.push(generatedId);
          idGenerated = true;
        }
      }

      options!.requestId = generatedId;

      let response = super.sendRequest(data, options) 
        .catch((error) => {
          let index = this.requestIdArray.indexOf(generatedId);
          if (index > -1) this.requestIdArray.splice(index, 1);
          throw error;
        });
      return response; 
    };
  }
}
