<template>
  <div class="modal-blackout" style="z-index: 9999;">
    <div class="modal-content">
      <p>Установка соединения...</p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
